












import { formatPostSlotTime } from '@/includes/logic/Slots/utils';
import { ChannelSlot } from "@/includes/types/Slots.types";

import { Vue, Component, Prop, Emit } from 'vue-property-decorator';

@Component({
  methods: {
    formatPostSlotTime
  }
})
export default class SlotItem extends Vue {
  @Prop() slotItem!: ChannelSlot

  @Prop({ type: Boolean, required: false, default: false }) disabled!: boolean

  @Prop({ type: Boolean, required: false, default: false }) readonly !: boolean

  @Emit()
  selectSlot(slot: ChannelSlot) {
    return slot
  }
}
