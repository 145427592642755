











































































import { ProjectFlowAccessor } from "./types";
import FlowAccessorConditions from "@/components/Flow/Accessor/components/FlowAccessorConditions.vue";
import Editor from "@/components/Flow/Editor";
import { InputSetups } from "@/mixins/input-setups";
import { successNotification } from "@/includes/services/NotificationService";

import ConfigField from "piramis-base-components/src/components/ConfigField/ConfigField.vue";
import ActivationActionData from "piramis-base-components/src/shared/modules/BotFeedback/ActivationAction/ActivationActionData.vue";
import DrawerWidthMixin from "piramis-base-components/src/logic/helpers/DrawerWidthMixin";

import { Component, Mixins, Prop, VModel, Watch } from 'vue-property-decorator';
import { instanceToInstance } from "class-transformer";
import { cloneDeep } from "lodash";

type ConditionData = Pick<ProjectFlowAccessor, 'conditions' | 'action'> | null

@Component({
  components: {
    FlowAccessorConditions,
    ConfigField,
    ActivationActionData
  }
})
export default class FlowAccessor extends Mixins(DrawerWidthMixin, InputSetups) {
  @VModel() accessor!: ProjectFlowAccessor | null

  @Prop() editor!: Editor

  accessorValue: ConditionData = null

  @Watch('accessor', { deep: true, immediate: true })
  onAccessorChange(value: ConditionData) {
    if (value) {
      this.accessorValue = {
        action: cloneDeep(value.action),
        conditions: instanceToInstance(value.conditions)
      }
    } else {
      this.accessorValue = null
    }
  }

  resetAccessorIfEmpty() {
    if (this.accessor) {
      if (this.accessor.action === null || !this.accessor.conditions.length) {
        this.accessor = null
      }
    }
  }

  handleDrawerClose() {
    if (this.accessor) {
      this.accessor.isDrawerVisible = false

      this.resetAccessorIfEmpty()
    }
  }

  saveAccessorModel() {
    if (this.accessor && this.accessorValue) {
      this.accessor.conditions = instanceToInstance(this.accessorValue.conditions)
      this.accessor.action = cloneDeep(this.accessorValue.action)

      successNotification()
    }
  }

  resetAccessor() {
    this.accessorValue = this.accessor = null

    successNotification()
  }

  created() {
    this.initializeSidebarWidth(992)
    this.resetAccessorIfEmpty()

    if (this.accessor) {
      const targets = this.accessor.getChannelMemberConditionTargets()

      if (targets.length) {
        this.editor.getChannelsInfo(targets)
      }
    }
  }
}
