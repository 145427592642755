




















































import SlotItem from "@/components/Slots/SlotItem.vue";
import { formatPostSlotTime } from "../../includes/logic/Slots/utils";
import { ChannelSlot } from "@/includes/types/Slots.types";

import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import { isEqual } from "lodash";

@Component({
  methods: {
    formatPostSlotTime,
    isEqual,
  },
  components: {
    SlotItem
  }
})
export default class ShowMoreSlots extends Vue {
  @Prop() slotItems!: Array<ChannelSlot>

  @Prop() selectedSlot!: ChannelSlot | null

  @Prop({ type: Boolean, required: false, default: false }) disabled!: boolean

  @Prop({ type: Boolean, required: false, default: false }) readonly !: boolean

  @Emit()
  selectSlot(slot: ChannelSlot) {
    return slot
  }

  isSlotModalOpen = false

  get containsActiveSlot() {
    return this.slotItems.some(s => isEqual(s, this.selectedSlot))
  }

  handleSlotClick(slot: ChannelSlot) {
    if (!this.readonly) {
      this.selectSlot(slot);

      this.isSlotModalOpen = false
    }
  }
}
